type Collection = {
	group?: boolean;
	sort?: boolean;
	skus: string[];
};
const collections: Record<string, Collection> = {
	"christmas-gifts": {
		skus: [],
	},
	"luxury-stocking-fillers": {
		sort: true,
		skus: [
			"010-01",
			"011-01",
			"205-02",
			"205-03",
			"232-05",
			"281-01",
			"281-02",
			"281-03",
			"282-01",
			"282-02",
			"3119-05",
			"496-05",
			"496-09",
			"746-04",
			"746-05",
			"746-07",
			"746-09",
			"785-01",
			"874-04",
			"874-12",
			"920-01",
		],
	},
	"gifts-under-250": {
		group: true,
		sort: true,
		skus: [
			"010-01",
			"011-01",
			"232-02",
			"232-05",
			"232-06",
			"244-02",
			"244-03",
			"281-01",
			"282-02",
			"496-04",
			"496-09",
			"517-02",
			"726-02",
			"726-03",
			"746-06",
			"746-08",
			"746-09",
			"746-10",
			"785-04",
			"785-09",
			"785-10",
			"882-03",
			"882-06",
			"882-07",
			"882-08",
			"925-01",
			"938-02",
			"938-04",
		],
	},
	"personalised-gifts": {
		sort: true,
		skus: [
			"205-05",
			"3011-07",
			"685-12",
			"717-13",
			"785-01",
			"785-05",
			"805-14",
			"805-34",
			"874-09",
			"874-12",
			"882-05",
		],
	},
	"gifts-for-her": {
		group: true,
		sort: true,
		skus: [
			"103-02",
			"103-03",
			"205-02",
			"205-05",
			"232-06",
			"244-07",
			"245-01",
			"254-04",
			"254-05",
			"268-05",
			"268-06",
			"281-01",
			"3009-04",
			"3011-04",
			"3050-23",
			"3050-33",
			"3050-37",
			"3067-03",
			"3097-20",
			"3097-22",
			"3126-07",
			"3129-0",
			"3129-01",
			"3136-03",
			"3140-03",
			"3146-01",
			"3147-01",
			"496-09",
			"670-04",
			"670-06",
			"685-06",
			"685-07",
			"685-16",
			"686-03",
			"686-11",
			"686-14",
			"686-19",
			"726-03",
			"726-06",
			"738-08",
			"746-04",
			"746-06",
			"746-07",
			"746-10",
			"785-04",
			"785-10",
			"874-05",
			"874-12",
			"882-06",
			"882-08",
			"882-10",
			"938-04",
			"938-05",
		],
	},
	"gifts-for-him": {
		group: true,
		sort: true,
		skus: [
			"112-01",
			"244-02",
			"245-02",
			"281-02",
			"3136-02",
			"3140-06",
			"3145-02",
			"3146-01",
			"3149-01",
			"399-01",
			"486-02",
			"496-05",
			"620-03",
			"670-03",
			"670-05",
			"670-07",
			"717-11",
			"717-12",
			"726-03",
			"726-05",
			"746-02",
			"746-06",
			"746-08",
			"746-09",
			"785-03",
			"785-09",
			"805-18",
			"805-24",
			"805-31",
			"805-34",
			"806-01",
			"806-07",
			"874-06",
			"874-08",
			"874-11",
			"882-03",
			"882-05",
			"882-07",
			"938-01",
		],
	},
	"tech-gifts": {
		group: true,
		sort: true,
		skus: [
			"244-06",
			"244-07",
			"245-01",
			"3009-02",
			"3009-03",
			"3145-02",
			"3146-01",
			"3148-02",
			"3149-01",
		],
	},
	"travel-gifts": {
		group: true,
		sort: true,
		skus: [
			"205-03",
			"254-05",
			"281-03",
			"282-01",
			"282-02",
			"3009-04",
			"3136-02",
			"3136-03",
			"3140-06",
			"3141-02",
			"3145-02",
			"3147-01",
			"659-02",
			"670-05",
			"670-06",
			"686-09",
			"686-10",
			"686-13",
			"717-07",
			"746-04",
			"746-05",
			"746-07",
			"785-09",
			"794-01",
			"874-06",
			"882-03",
			"882-05",
		],
	},
};

export function hasCustomCollection(slug?: string) {
	return slug && slug in collections;
}

export function getCustomCollection(slug?: string) {
	if (!slug) return;
	return collections[slug];
}
